<template>
  <q-form ref="editForm">
    <c-card title="텍스트 컴포넌트" height="700px" class="cardClassDetailForm">
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            :editable="editable"
            label="기본"
            name="col1"
            v-model="data.col1">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            :editable="editable"
            label=""
            name="col1"
            v-model="data.col2">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            :required="true"
            :editable="editable"
            label="필수값"
            name="col1"
            v-model="data.col1">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            :disabled="true"
            :editable="editable"
            label="disable"
            name="col1"
            v-model="data.col1">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            :counter="true"
            :maxlength="25"
            :editable="editable"
            label="counter"
            name="col1"
            v-model="data.col1">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            prefix="앞"
            :editable="editable"
            label="prefix 텍스트 넣기"
            name="col1"
            v-model="data.col1">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            suffix="뒤"
            :editable="editable"
            label="prefix 텍스트 넣기"
            name="col1"
            v-model="data.col1">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            :beforeIcon="icons"
            :editable="editable"
            label="before icon 넣기"
            name="col1"
            v-model="data.col1">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            :prependIcon="icons"
            :editable="editable"
            label="prepend icon 넣기"
            name="col1"
            v-model="data.col1">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            :appendIcon="icons"
            :editable="editable"
            label="append icon 넣기"
            name="col1"
            v-model="data.col1">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            :afterIcon="icons"
            :editable="editable"
            label="afterIcon icon 넣기"
            name="col1"
            v-model="data.col1">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            :prependIcon="icons2"
            :editable="editable"
            label="icon 클릭"
            name="col1"
            v-model="data.col1"
            @iconclick="iconclick">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            :editable="editable"
            label="mask 전화번호"
            name="col3"
            mask="(###)-###-####"
            v-model="data.col3">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            :editable="editable"
            type="password"
            label="password"
            name="col3"
            v-model="data.col4">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            :editable="editable"
            type="time"
            label="time"
            name="col5"
            v-model="data.col5">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            :editable="editable"
            type="date"
            label="date"
            name="col6"
            v-model="data.col6">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-field
            :editable="editable"
            label="default는 user만"
            name="col7"
            v-model="data.col7">
          </c-field>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-field
            :editable="editable"
            :data="data"
            deptValue="col10"
            type="dept_user"
            label="부서&사용자"
            name="col8"
            v-model="data.col8">
          </c-field>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-field
            :editable="editable"
            :data="data"
            plantValue="col11"
            deptValue="col10"
            type="plant_dept_user"
            label="사업장&부서&사용자"
            name="col9"
            v-model="data.col9">
          </c-field>
        </div>
      </template>
    </c-card>
  </q-form>
</template>

<script>
export default {
  name: 'text',
  data() {
    return {
      editable: true,
      data: {
        col1: '',
        col2: '라벨 없음',
        col3: '',
        col4: '',
        col5: '',
        col6: '',
        col7: '',
        col8: '',
        col9: '',
        col10: '',
        col11: '',
      },
      icons: [
        { name: 'notifications_active', click: false, }
      ],
      icons2: [
        { name: 'notifications_active', click: true, callbackName: 'iconclick' }
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
    },
    iconclick() {
      window.alert('아이콘 클릭')
    }
  }
};
</script>
